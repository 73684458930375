<template>
  <div>
    <ResendMailModal
      v-if="openResendMailModal"
      @close="openResendMailModal = false"
      :firstName="firstNameModal"
      :lastName="lastNameModal"
    >
    </ResendMailModal>
    <ConfirmationModal
      v-if="isCloseAccountModal"
      @close="isCloseAccountModal = false"
      :headerTxt="
        rehabilitatorToDisable.isEnabled
          ? `Fermer le compte de ${rehabilitatortoDisableFullName}`
          : `Réactiver le compte de ${rehabilitatortoDisableFullName}`
      "
      :modalMessage="
        rehabilitatorToDisable.isEnabled
          ? rehabilitatortoDisableFullName +
            ` n’aura
      plus accès à son compte Lifebloom.Si besoin, vous pourrez réactiver son compte plus
      tard`
          : `Souhaitez-vous vraiment réactiver le compte de ${rehabilitatortoDisableFullName}`
      "
      :ButtonConfirmationTxt="rehabilitatorToDisable.isEnabled ? 'Fermer' : 'Réactiver'"
      @confirm="closeAccount"
    />
    <Modal
      v-if="openEditModal"
      @close="openEditModal = false"
      className="size-modal edit-modal"
    >
      <div class="container-modal">
        <span class="font-title-modal">Informations du rééducateur</span>
        <div class="flex">
          <Input
            v-model="rehabilitator.firstName"
            :labelClass="'flex'"
            :containerClass="'w-100'"
            title="Nom *"
          />
          <Input
            v-model="rehabilitator.lastName"
            title="Prénom *"
            :labelClass="'flex'"
            :containerClass="'w-100'"
          />
        </div>
        <div class="flex">
          <Input
            v-model="rehabilitator.phone"
            :labelClass="'flex'"
            :containerClass="'w-100'"
            title="Téléphone"
          />
          <Input
            v-model="rehabilitator.comment"
            title="Comment"
            :labelClass="'flex'"
            :containerClass="'w-100'"
          />
          <div>
            <Input
              v-model="rehabilitator.email"
              title="Adresse mail *"
              :type="'email'"
              :labelClass="'flex'"
              :containerClass="'w-100'"
              @input="errorMsgEmail = false"
              :inputClassName="errorMsgEmail ? 'border-red' : ''"
            />
            <span class="email-warning-txt" v-if="errorMsgEmail"
              >Veuillez entrer un email valide</span
            >
          </div>
        </div>
        <div class="width-btn">
          <Button
            title="Sauvegarder"
            type="secondary"
            @click="saveRehabilator"
            :disabled="isReadyToSave"
          />
        </div>
      </div>
    </Modal>
    <Modal
      v-if="openCommentDetailModal"
      @close="openCommentDetailModal = false"
      className="size-modal container-comment-modal"
    >
      <div class="container-modal comment-modal">
        <span class="font-title-modal">Commentaire</span>
        <Input
          v-model="rehabilitator.comment"
          :labelClass="'flex'"
          :containerClass="'w-100 comment-input'"
          isDisabled
          type="textarea"
        />
      </div>
      <div class="w-10 mr-auto">
        <Button
          title="Fermer"
          type="secondary"
          size="small"
          @click="openCommentDetailModal = false"
        />
      </div>
    </Modal>
    <div class="buttons-container">
      <router-link :to="{ name: 'MonitoringRehabilitators' }">
        <Button title="Tableaux" type="primary" :active="true" />
      </router-link>
      <router-link :to="{ name: 'MonitoringRehabilitatorsCharts' }">
        <Button title="Graphs" type="primary" />
      </router-link>
    </div>
    <div class="monitoring-table">
      <table aria-describedby="monitoring-table">
        <thead>
          <tr>
            <th
              v-for="(group, i) in displayColumns"
              :key="`cols-${i}`"
              :colspan="group.fields.length"
              class="group group-head"
            >
              {{ group.title }}
            </th>
          </tr>
          <tr>
            <template v-for="(group, i) in displayColumns">
              <template v-for="(col, j) in group.fields">
                <th
                  :key="`col-${i}-${j}`"
                  :class="{ sortable: col.sortable, group: j === 0 }"
                  @click="sort(group, col)"
                >
                  {{ col.title }}
                </th>
              </template>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(rehabilitator, i) in rehabilitatorsList"
            :key="`rehabilitator-${i}`"
          >
            <td>{{ rehabilitator.firstName }}</td>
            <td>{{ rehabilitator.lastName }}</td>
            <td>{{ rehabilitator.email }}</td>
            <td>{{ rehabilitator.phone }}</td>
            <td>
              <div
                v-if="rehabilitator.comment"
                @click="openCommentDetail(rehabilitator)"
                class="pointer"
              >
                <span v-if="rehabilitator.comment.length > 40">
                  {{ rehabilitator.comment.slice(0, 40) + "..." }}
                </span>
                <span v-else>
                  {{ rehabilitator.comment }}
                </span>
              </div>
            </td>
            <td>
              <div class="svg-container">
                <SVGEdit @click="editReeducator(rehabilitator)" />
              </div>
            </td>
            <td>
              <div>
                <Button
                  :title="'Renvoyer le lien'"
                  :type="'primary'"
                  size="small"
                  @click="resendSignupLink(rehabilitator)"
                />
              </div>
            </td>
            <td>
              <div>
                <Button
                  :title="'Fermer le compte'"
                  :type="'delete'"
                  size="small"
                  disabled
                />
                <!-- <Button
                      :title="
                      rehabilitator.isEnabled ? 'Fermer le compte' : 'Réactiver le compte'
                    "
                      :type="rehabilitator.isEnabled ? 'primary' : 'delete'"
                      size="small"
                      @click="openConfirmModal(rehabilitator)"
                  /> -->
              </div>
            </td>
            <td>
              <div>
                <Button
                  title="Supprimer le compte"
                  type="delete-type"
                  :disabled="true"
                  size="small"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Button from "@/components/Button"
import Modal from "@/components/ConfirmationModal/Modal.vue"
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal.vue"
import Input from "@/components/Forms/Fields/Input.vue"
import SVGEdit from "@/assets/icons/edit.svg"
import { isEmail } from "@/utils/validation"
import ResendMailModal from "../../components/ConfirmationModal/ResendMailModal.vue"

export default {
  name: "LancementReeducator",
  components: {
    SVGEdit,
    Button,
    ConfirmationModal,
    Modal,
    Input,
    ResendMailModal
  },
  data() {
    return {
      openResendMailModal: false,
      lastNameModal: "",
      firstNameModal: "",
      openCommentDetailModal: false,
      errorMsgEmail: false,
      isCloseAccountModal: false,
      openEditModal: false,
      rehabilitator: {},
      rehabilitatorToDisable: {},
      columns: {
        main: {
          name: "main",
          title: "",
          display: true,
          hidden: true,
          fields: [
            { title: "Prénom", sortable: true, name: "firstName", editable: true },
            { title: "Nom", sortable: true, name: "lastname", editable: true },
            { title: "Email" },
            { title: "Téléphone" },
            { title: "Commentaire", editable: true },
            {
              title: "Editer la ligne",
              name: "edit"
            },
            {
              title: "Renvoie du mail d'inscription",
              name: "manage"
            },
            {
              title: "Fermeture Compte",
              name: "closeCompte"
            },
            {
              title: "Supprimer Compte",
              name: "deleteCompte"
            }
          ]
        },
        area: {
          name: "area",
          title: "Zone",
          display: false,
          fields: [
            { title: "Pays" },
            { title: "Agence(s)" },
            { title: "Département / Province" }
          ]
        },
        rehabilitatorsDetails: {
          name: "rehabilitatorDetails",
          title: "Détails Rééducateur",
          display: false,
          fields: [
            { title: "Code Postal", sortable: true, name: "zipcode", editable: true },
            { title: "Ville", sortable: true, name: "city", editable: true },
            { title: "N°", editable: true },
            { title: "Voie", editable: true },
            { title: "Précision", editable: true },
            { title: "Téléphone", sortable: true, name: "phone", editable: true },
            { title: "Email", sortable: true, name: "email", editable: true },
            { title: "N° RPPS", sortable: true, name: "rpps", editable: true },
            { title: "N° ADELI", sortable: true, name: "adeli", editable: true },
            { title: "ID Rééducateur", name: "id" }
          ]
        },
        usage: {
          name: "usage",
          title: "Usage (7 derniers jours)",
          display: false,
          fields: [
            { title: "Indépendant / Nom établissement" },
            { title: "Temps passé en ligne" },
            { title: "Nombre de patients", sortable: true, name: "patientsCount" },
            { title: "Nombre affichage profils / patients" },
            { title: "Tx patient avec Autoréédud prescrit" },
            { title: "Temps moyens Chgt Autorééduc de chaque patient ce mois-ci" },
            {
              title: "Tx patients ayant eu 1 séance rééduc digital (ce mois ci)",
              sortable: true,
              name: "patientsSelfWorkoutPercent"
            },
            { title: "Tx séances rééduc digitale avec usage temps réel" }
          ]
        },
        closeAccount: {
          name: "closeAccount",
          title: "Fin de compte",
          display: false,
          fields: [{ title: "Fermeture de compte" }, { title: "Suppression de compte" }]
        }
      },
      sortBy: {
        group: "main",
        key: "firstname",
        order: 1
      }
    }
  },
  async created() {
    await this.$store.dispatch("admin/getReeducatorsApplications")
  },
  methods: {
    async resendSignupLink(rehabilitator) {
      const resp = await this.$store.dispatch(
        "admin/resendReeducatorSignUpLink",
        rehabilitator
      )
      if (resp.status === 204) {
        this.firstNameModal = rehabilitator.firstName
        this.lastNameModal = rehabilitator.lastName
        this.openResendMailModal = true
      }
    },
    async saveRehabilator() {
      if (isEmail(this.rehabilitator.email)) {
        await this.$store.dispatch(
          "admin/editRehabilitatorApplication",
          this.rehabilitator
        )
        this.openEditModal = false
      } else {
        this.errorMsgEmail = true
      }
    },
    async closeAccount() {
      await this.$store.dispatch("admin/closeRehabilitator", this.rehabilitatorToDisable)
      this.isCloseAccountModal = false
    },
    openCommentDetail(data) {
      this.rehabilitator = { ...data }
      this.openCommentDetailModal = true
    },
    editReeducator(data) {
      this.rehabilitator = { ...data }
      this.openEditModal = true
    },
    openConfirmModal(rehabilitator) {
      this.rehabilitatorToDisable = rehabilitator
      this.isCloseAccountModal = true
    },
    sort(group, column) {
      if (column.sortable) {
        const { name } = column

        if (this.sortBy.key === name) {
          this.sortBy.order = -this.sortBy.order
        } else {
          this.sortBy = {
            group: group.name,
            key: name,
            order: -1
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      rehabilitatorsList: "admin/reeducatorsApplications"
    }),
    isReadyToSave() {
      return (
        !this.rehabilitator.firstName ||
        !this.rehabilitator.lastName ||
        !this.rehabilitator.email
      )
    },
    rehabilitatortoDisableFullName() {
      return (
        this.rehabilitatorToDisable.firstName + " " + this.rehabilitatorToDisable.lastName
      )
    },
    displayColumns() {
      const cols = []
      Object.keys(this.columns).forEach((group) => {
        if (this.columns[group].display) {
          cols.push(this.columns[group])
        }
      })
      return cols
    },
    rehabilitators() {
      return this.rehabilitatorsList.map((rehabilitator) => {
        return {
          main: {
            lastActivity: "",
            firstname: rehabilitator.firstName,
            lastname: rehabilitator.lastName,
            specialization: "",
            commentary: ""
          }
        }
      })
    }
  },
  watch: {
    async "columns.usage.display"(displayUsage) {
      if (displayUsage) {
        await this.$store.dispatch("admin/getRehabilitatorsPatients")
        await this.$store.dispatch("admin/getRehabilitatorsStats")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .edit-modal {
  min-height: unset !important;
}

.container-comment-modal {
  min-height: 500px !important;

  .comment-modal {
    padding: 0 50px;

    .font-title-modal {
      display: block;
      margin-bottom: 25px;
      font-weight: bold;
    }
  }

  .comment-input textarea {
    min-height: 350px;
  }
}

.buttons-container {
  margin-top: 24px;
  display: flex;

  a {
    margin: 0 6px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.monitoring-table {
  display: inline-block;
  overflow: scroll;
  width: 100%;
  padding-bottom: 20px;
  margin-top: 50px;
}

table {
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  table-layout: fixed;

  thead {
    font-size: 1.1rem;
    font-weight: 420;
    text-align: left;
    background: $main-color;
    color: $white-color;

    .group {
      border-left: 2px solid $white-color;

      &:first-child {
        border-left: none;
      }
    }

    .group-head {
      font-size: 1.2rem;
      text-align: center;
      border-radius: 10px 10px 0 0;
    }

    .sortable {
      cursor: pointer;
      transition: all 0.25s;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(2n) {
        background: #f7f7f7;
      }
    }
  }

  th,
  td {
    vertical-align: middle;
  }

  th {
    padding: 10px 50px;
    user-select: none;
    vertical-align: bottom;
  }

  td {
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }

  .separator {
    border-left: 2px solid $main-color;
  }
}

.svg-container {
  width: 28px;
  margin: auto 16px;
  margin-top: 5px;
  cursor: pointer;
}

.container-modal {
  text-align: center;
  padding: 0 200px;
}

.container-oxilio-modal {
  text-align: center;
}

.font-title-modal {
  font-size: 18px;
}

.text-info {
  display: flex;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 420;
  font-size: 22px;
  line-height: 24px;
  margin-top: 25px;
  align-items: center;
  color: #0c2298;
}

.container-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.width-btn {
  width: 176px;
  margin: 0 auto;
  margin-top: 20px;
}

.small-btn-container {
  width: 98px;
  margin: 8px auto;
}

.title-table {
  color: #0c2298;
  margin-bottom: 20px;
  font-size: 18px;
}

.email-warning-txt {
  font-size: 15px;
  color: red;
}

.buttons-container {
  margin-top: 24px;
  display: flex;

  a {
    margin: 0 6px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
